import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacy = () => {

  return (
    <Layout title="ChatForge | UK Chatbot Services">
      <SEO title="Privacy" />
      <article class="section" >
        <h2>Privacy Policy</h2>
        <p>Your privacy is important to us. It is Software Imaging Limited's policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://chatforge.ai">https://chatforge.ai</a>, and other sites we own and operate.</p>
        <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
        <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.</p>
        <h3>Information Collection And Use</h3>
        <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your email ("Personal Information").</p>
        <h3>Log Data</h3>
        <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").</p>
        <p>This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
        <p>In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this Log Data.</p>
        <h3 id={"cookies"}>Cookies</h3>
        <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>
        <p>Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>
        <h3>Other information</h3>
        <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
        <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
        <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information.</p>
        <h3>Changes To This Privacy Policy</h3>
        <p>This Privacy Policy is effective as of the 7th Feburary 2020 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>
        <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
        <p>If we make any material changes to this Privacy Policy, we will notify you by placing a prominent notice on our website.</p>
        <p>If you have any questions about how we handle user data and personal information or would like to exercise your rights under GDPR or COPA, feel free to contact us at chatforge@softwareimaging.com.</p>
      </article>
    </Layout>
  )
}

export default Privacy;